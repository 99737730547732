import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { useCurrentStopTimes } from "../hooks/useCurrentStopTimes";
import { formatTime } from "../utils";
import { Arrival } from "./Arrival";
import { Countdown } from "./Countdown";
import { Delay } from "./Delay";
import { Error } from "./Error";
import { KmkId } from "./KmkId";
import { LiveIcon } from "./LiveIcon";
import { ReloadLink } from "./ReloadLink";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import { StopLink } from "./StopLink";
import { Strikethrough } from "./Strikethrough";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { getUrlForStop, getUrlForStopWithNumber, getUrlForTrip } from "../urls";
import { useMemo } from "react";
import { useStopsByName } from "../hooks/useStopsByName";

interface CurrentStopTimesProps {
  stopName: string;
  stopNumber: string | null;
}

export function CurrentStopTimes({
  stopName,
  stopNumber,
}: CurrentStopTimesProps) {
  const { currentStopTimes, loading, error } = useCurrentStopTimes(
    stopName,
    10_000
  );

  const { stops } = useStopsByName(stopName);

  const stopNumberToColorMap = useMemo(() => {
    const result = new Map();
    for (const stop of stops) {
      result.set(
        stop.stop_num,
        stop.tram && stop.bus ? "magenta" : stop.tram ? "red" : "blue"
      );
    }
    return result;
  }, [stops]);

  const filteredCurrentStopTimes = useMemo(() => {
    if (stopNumber !== null) {
      return currentStopTimes.filter(
        (currentStopTime) => currentStopTime.stop_num === stopNumber
      );
    }
    return currentStopTimes;
  }, [currentStopTimes, stopNumber]);

  const { isFavorite } = useFavoriteVehicles();

  if (error?.message === "NOT FOUND") {
    return (
      <Container className="g-sm-0">
        <Alert variant="secondary">
          Nie znaleziono przystanku. <ReloadLink />
        </Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="g-sm-0">
        <Error error={error} />
      </Container>
    );
  }

  return (
    <Table
      size="sm"
      bordered
      hover
      className="table-td-align-middle table-layout-fixed text-nowrap"
    >
      <thead>
        <tr>
          <th style={{ width: "20%" }} className="text-center">
            Czas
            <span className="d-none d-xl-inline"> do odjazdu</span>
          </th>
          <th style={{ width: "10%" }} className="text-center">
            Linia
          </th>
          <th>Kierunek</th>
          <th style={{ width: "15%" }} className="text-center">
            Odjazd
          </th>
          <th style={{ width: "15%" }} className="text-center">
            Pojazd
          </th>
          <th style={{ width: "7.5%" }} className="text-center">
            <span className="d-xl-none">Ⓟ</span>
            <span className="d-none d-xl-inline">Peron</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredCurrentStopTimes.map((currentStopTime) => (
          <tr
            key={currentStopTime.key}
            className={
              currentStopTime.stopping
                ? "bg-warning bg-opacity-25"
                : currentStopTime.old
                ? "bg-dark bg-opacity-10 opacity-75"
                : undefined
            }
          >
            {/* TODO: highlight if departing */}
            <td className="text-center">
              {currentStopTime.old ? (
                "✓"
              ) : (
                <Countdown
                  timestamp={
                    currentStopTime.live
                      ? currentStopTime.predicted_departure_timestamp!
                      : currentStopTime.planned_departure_timestamp
                  }
                />
              )}
              {currentStopTime.live && <LiveIcon />}
            </td>
            <td className="text-center">
              {currentStopTime.route_short_name ? (
                <div
                  style={currentStopTime.hidden ? { opacity: 0.2 } : undefined}
                >
                  <RouteShortNameBox
                    routeShortName={currentStopTime.route_short_name}
                  />
                </div>
              ) : (
                <RouteShortNameBoxPlaceholder />
              )}
            </td>
            <td className="text-truncate">
              <Strikethrough enabled={currentStopTime.old}>
                <StopLink
                  stopName={currentStopTime.trip_headsign}
                  removeNz
                  expandDepotName
                />
              </Strikethrough>
            </td>
            <td className="text-center">
              <Link
                to={getUrlForTrip(
                  currentStopTime.category,
                  currentStopTime.service_id,
                  currentStopTime.block_id,
                  currentStopTime.trip_id
                )}
                className="hidden-link"
              >
                <Strikethrough enabled={currentStopTime.old}>
                  {formatTime(currentStopTime.planned_departure_time)}
                  {currentStopTime.arrival && <Arrival />}
                </Strikethrough>
              </Link>
              <Delay seconds={currentStopTime.predicted_delay} />
            </td>
            <td className="text-center">
              {currentStopTime.kmk_id !== null && (
                <div
                  className={
                    isFavorite(currentStopTime.kmk_id) && !currentStopTime.old
                      ? "d-inline-block px-1 bg-warning bg-opacity-50"
                      : undefined
                  }
                >
                  <KmkId kmk_id={currentStopTime.kmk_id} />
                </div>
              )}
            </td>
            <td
              className="text-center"
              style={{
                color:
                  stopNumberToColorMap.get(currentStopTime.stop_num) ?? "gray",
              }}
            >
              <Link
                to={
                  currentStopTime.stop_num !== null
                    ? getUrlForStopWithNumber(
                        stopName,
                        currentStopTime.stop_num
                      )
                    : getUrlForStop(stopName)
                }
                className="hidden-link small"
              >
                {currentStopTime.stop_num}
              </Link>
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={6} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && filteredCurrentStopTimes.length === 0 && (
          <tr>
            <td colSpan={6} className="py-3 text-center text-secondary">
              Brak odjazdów{stopNumber !== null && " z wybranego przystanku"}.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
