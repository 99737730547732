import "./Map.css";

import { featureCollection, lineString, point } from "@turf/helpers";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MapView, {
  AttributionControl,
  ControlPosition,
  FullscreenControl,
  GeolocateControl,
  IControl,
  Layer,
  MapLayerMouseEvent,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
  useControl,
} from "react-map-gl/maplibre";
import { KmkId } from "../components/KmkId";
import { Navigation } from "../components/Navigation";
import PageTitle from "../components/PageTitle";
import {
  Source as SourceType,
  useActiveVehicles,
  Vehicle,
} from "../hooks/useActiveVehicles";
import { StopGroup, useStopGroups } from "../hooks/useStopGroups";
import { useStops } from "../hooks/useStops";
import { Link, useNavigate, useParams } from "react-router-dom";
import { StopLink } from "../components/StopLink";
import { Counter } from "../components/Counter";
import {
  decodeStopName,
  getFullSourceName,
  isValidKmkId,
  isValidTtssVehicleId,
} from "../utils";
import { useTicketZones } from "../hooks/useTicketZones";
import {
  ColorSpecification,
  DataDrivenPropertyValueSpecification,
} from "maplibre-gl";
import { useVehiclePath } from "../hooks/useVehiclePath";
import { useMapIcons } from "../hooks/useMapIcons";
import {
  getUrlForBlock,
  getUrlForMap,
  getUrlForRoute,
  getUrlForStop,
  getUrlForStopOnMap,
  getUrlForStopWithNumber,
  getUrlForVehicleOnMap,
} from "../urls";
import { getNearestFeature } from "../mapUtils";
import { RouteShortNameFilterModal } from "../components/RouteShortNameFilterModal";
import { renderToStaticMarkup } from "react-dom/server";
import { useFilteredRouteShortNames } from "../hooks/useFilteredRouteShortNames";
import { useLocalStorage } from "usehooks-ts";
import { Error } from "../components/Error";
import Spinner from "react-bootstrap/Spinner";
import { StatusIcon } from "../components/StatusIcon";

const filterIconSvg = renderToStaticMarkup(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 20 20"
  >
    <polygon points="3,4 17,4 11.5,10 11.5,15 8.5,17 8.5,10" />
  </svg>
);

const filterIconUrl = `url(data:image/svg+xml;base64,${btoa(filterIconSvg)})`;

interface FilterControlOptions {
  onClick: () => void;
}

class FilterControl implements IControl {
  _onClick: () => void;
  _div: HTMLDivElement | undefined;
  _button: HTMLButtonElement | undefined;

  constructor(options: FilterControlOptions) {
    this._onClick = options.onClick;
  }

  onAdd() {
    const div = window.document.createElement("div");
    div.className = "maplibregl-ctrl maplibregl-ctrl-group";

    const button = window.document.createElement("button");
    button.type = "button";

    const span = window.document.createElement("span");
    span.style.backgroundImage = filterIconUrl;
    span.className = "maplibregl-ctrl-icon";
    span.setAttribute("aria-hidden", "true");

    div.appendChild(button);
    button.appendChild(span);
    button.addEventListener("click", this._onClick);

    this._div = div;
    this._button = button;

    return div;
  }

  onRemove() {
    this._button?.removeEventListener("click", this._onClick);
    this._div?.parentNode?.removeChild(this._div);
  }
}

interface ReactFilterControlProps {
  position?: ControlPosition;
  onClick: () => void;
}

function ReactFilterControl(props: ReactFilterControlProps) {
  useControl<FilterControl>(
    () => new FilterControl({ onClick: props.onClick }),
    { position: props.position }
  );

  return null;
}

interface SourceSelectControlOptions {
  value: SourceType;
  onChange: (source: SourceType) => void;
}

class SourceSelectControl implements IControl {
  _value: SourceType;
  _onChange: (source: SourceType) => void;
  _select: HTMLSelectElement | undefined;
  _listener: EventListener | undefined;

  constructor(options: SourceSelectControlOptions) {
    this._value = options.value;
    this._onChange = options.onChange;
  }

  onAdd() {
    const select = window.document.createElement("select");
    select.className = "maplibregl-ctrl form-select form-select-sm";

    for (const source of ["gtfs", "mobilis", "ttss", "kokon"]) {
      const option = window.document.createElement("option");
      option.value = source;
      option.text = getFullSourceName(source);
      if (source === "kokon") {
        option.disabled = true;
      }
      select.appendChild(option);
    }

    select.value = this._value;

    const listener = () => this._onChange(select.value as SourceType);
    select.addEventListener("change", listener);

    this._select = select;
    this._listener = listener;
    return select;
  }

  onRemove() {
    if (this._listener !== undefined) {
      this._select?.removeEventListener("change", this._listener);
    }
    this._select?.parentNode?.removeChild(this._select);
  }
}

interface ReactSourceSelectControlProps {
  value: SourceType;
  onChange: (source: SourceType) => void;
  position?: ControlPosition;
}

function ReactSourceSelectControl(props: ReactSourceSelectControlProps) {
  useControl<SourceSelectControl>(
    () =>
      new SourceSelectControl({
        value: props.value,
        onChange: props.onChange,
      }),
    { position: props.position }
  );

  return null;
}

interface VehicleMarkersProps {
  vehicles: Vehicle[];
}

function VehicleMarkers({ vehicles }: VehicleMarkersProps) {
  useMapIcons();

  const [geojson, outdatedThreshold] = useMemo(() => {
    return [
      featureCollection(
        vehicles.map((v) => point([v.longitude!, v.latitude!], v))
      ),
      Date.now() / 1000 - 7 * 60, // 7 min
    ];
  }, [vehicles]);

  return (
    <Source id="vehicles" type="geojson" data={geojson}>
      <Layer
        id="vehicles"
        type="symbol"
        layout={{
          "text-field": [
            "case",
            [
              "all",
              ["==", ["get", "trip_headsign"], "Zajezdnia Nowa Huta"],
              ["!=", ["get", "route_short_name"], "4"],
              ["!=", ["get", "route_short_name"], "22"],
              ["!=", ["get", "route_short_name"], "601"],
            ],
            "ZNH",
            ["==", ["get", "trip_headsign"], "PT"],
            "ZP",
            // @ts-expect-error
            ["==", ["typeof", ["get", "route_short_name"]], "null"],
            "?",
            ["get", "route_short_name"],
          ],
          "text-font": ["Open Sans Bold"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 6, 10, 15, 16],
          "text-allow-overlap": true,
          "icon-image": [
            "concat",
            ["get", "category"],
            [
              "case",
              ["!=", ["to-string", ["get", "bearing"]], ""],
              "-arrow",
              "",
            ],
            [
              "case",
              ["<=", ["number", ["get", "timestamp"]], outdatedThreshold],
              "-outdated",
              "",
            ],
          ],
          "icon-size": vehicleIconSizeExpression,
          "icon-rotate": ["get", "bearing"],
          "icon-allow-overlap": true,
          "symbol-sort-key": ["get", "timestamp"],
        }}
        paint={{
          "text-color": "white",
          "icon-opacity": [
            "case",
            [
              "in",
              ["get", "trip_headsign"],
              [
                "literal",
                ["Wyjazd na linię", "Zjazd do zajezdni", "Przejazd techniczny"],
              ],
            ],
            0.4,
            1,
          ],
          "text-halo-color": [
            "case",
            ["<=", ["number", ["get", "timestamp"]], outdatedThreshold],
            "rgba(0,0,0,0.2)",
            [
              "match",
              ["get", "category"],
              "tram",
              "rgba(255,0,0,0.5)",
              "bus",
              "rgba(0,0,255,0.5)",
              "mobilis",
              "rgba(0,0,128,0.5)",
              "rgba(255,128,0,0.5)",
            ],
          ],
          "text-halo-width": 0.5,
        }}
      />
    </Source>
  );
}

interface StopGroupsMarkersProps {
  stopGroups: StopGroup[];
  selectedStopName: string | null;
  hoveredStopName: string | null;
}

function StopGroupsMarkers({
  stopGroups,
  selectedStopName,
  hoveredStopName,
}: StopGroupsMarkersProps) {
  const geojson = useMemo(() => {
    return featureCollection(
      stopGroups
        .filter((sg) => sg.stop_lat !== null && sg.stop_lon !== null)
        .map((sg) => point([sg.stop_lon!, sg.stop_lat!], sg))
    );
  }, [stopGroups]);

  const colorExpression = useMemo(
    () =>
      [
        "case",
        ["==", ["get", "stop_name"], selectedStopName ?? ""],
        "black",
        ["==", ["get", "stop_name"], hoveredStopName ?? ""],
        "dodgerblue",
        "gray",
      ] as DataDrivenPropertyValueSpecification<string>,
    [selectedStopName, hoveredStopName]
  );

  return (
    <Source id="stop_groups" type="geojson" data={geojson}>
      <Layer
        id="stop_groups_circles"
        type="circle"
        paint={{
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            6,
            1.5,
            minZoomLevelForStopGroupLabels,
            1.5,
            15,
            3,
          ],
          "circle-color": colorExpression,
          "circle-stroke-width": 20, // hitbox
          "circle-stroke-color": "transparent",
        }}
      />
      <Layer
        id="stop_groups_labels"
        type="symbol"
        minzoom={minZoomLevelForStopGroupLabels}
        layout={{
          "text-field": ["get", "stop_name"],
          "text-font": ["Open Sans Bold"],
          "text-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            minZoomLevelForStopGroupLabels,
            9,
            15,
            13,
          ],
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": colorExpression,
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

const stopsColorExpression = [
  "case",
  ["all", ["get", "tram"], ["get", "bus"]],
  "magenta",
  ["get", "tram"],
  "red",
  ["get", "bus"],
  "blue",
  "limegreen",
] as DataDrivenPropertyValueSpecification<string>;

const stopsTextExpression = [
  "case",
  ["all", ["get", "tram"], ["get", "bus"]],
  "TA",
  ["get", "tram"],
  "T",
  ["get", "bus"],
  "A",
  "?",
] as DataDrivenPropertyValueSpecification<string>;

const vehicleIconSizeExpression = [
  "interpolate",
  ["linear"],
  ["zoom"],
  6,
  0.25,
  15,
  0.45,
] as DataDrivenPropertyValueSpecification<number>;

const minZoomLevelForStopGroupLabels = 13;
const minZoomLevelForStopMarkers = 15;
const stopZoomLevel = minZoomLevelForStopMarkers + 0.1;

function StopsMarkers() {
  const { stops } = useStops();

  const geojson = useMemo(() => {
    return featureCollection(
      stops
        .filter((s) => s.stop_lat !== null && s.stop_lon !== null)
        .map((s) => point([s.stop_lon!, s.stop_lat!], s))
    );
  }, [stops]);

  return (
    <Source id="stops" type="geojson" data={geojson}>
      <Layer
        id="stops_outlines"
        type="circle"
        minzoom={minZoomLevelForStopMarkers}
        paint={{
          "circle-radius": 9,
          "circle-color": "white",
        }}
      />
      <Layer
        id="stops_circles"
        type="circle"
        minzoom={minZoomLevelForStopMarkers}
        paint={{
          "circle-radius": 7,
          "circle-color": "transparent",
          "circle-stroke-width": 1.5,
          "circle-stroke-color": stopsColorExpression,
        }}
      />
      <Layer
        id="stops_letters"
        type="symbol"
        minzoom={minZoomLevelForStopMarkers}
        layout={{
          "text-field": stopsTextExpression,
          "text-font": ["Open Sans Bold"],
          "text-size": 11,
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": stopsColorExpression,
        }}
      />
      <Layer
        id="stops_labels"
        type="symbol"
        minzoom={minZoomLevelForStopMarkers}
        layout={{
          "text-field": ["get", "stop_num"],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "left",
          "text-offset": [0.85, 0],
          "text-allow-overlap": true,
        }}
        paint={{
          "text-color": stopsColorExpression,
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

const ticketZonesColorExpression = [
  "match",
  ["get", "Strefa"],
  "I",
  "dodgerblue",
  "II",
  "limegreen",
  "III",
  "goldenrod",
  "tomato",
] as DataDrivenPropertyValueSpecification<ColorSpecification>;

const emptyFeatureCollection = featureCollection([]);

const emptyLine = lineString([
  [0, 0],
  [0, 0],
]);

function TicketZonesPolygons() {
  const { ticketZones } = useTicketZones();

  return (
    <Source
      id="ticket-zones"
      type="geojson"
      data={ticketZones ?? emptyFeatureCollection}
    >
      <Layer
        id="ticket_zones_fill"
        type="fill"
        maxzoom={11}
        paint={{
          "fill-color": ticketZonesColorExpression,
          "fill-opacity": ["interpolate", ["linear"], ["zoom"], 9, 0.2, 11, 0],
        }}
      />
      <Layer
        id="ticket_zones_lines"
        type="line"
        maxzoom={13}
        paint={{
          "line-color": ticketZonesColorExpression,
          "line-width": 1.5,
          "line-opacity": ["interpolate", ["linear"], ["zoom"], 11, 0.3, 13, 0],
        }}
      />
      <Layer
        id="ticket_zones_labels"
        type="symbol"
        maxzoom={13}
        layout={{
          "text-field": ["get", "Nazwa"],
          "text-font": ["Open Sans Italic"],
          "text-size": 11,
          "text-allow-overlap": true,
          "icon-allow-overlap": true,
        }}
        paint={{
          "text-color": "gray",
          "text-opacity": ["interpolate", ["linear"], ["zoom"], 11, 1, 13, 0],
        }}
      />
    </Source>
  );
}

interface HoveredVehiclePopupProps {
  vehicle: Vehicle | null;
}

function HoveredVehiclePopup({ vehicle }: HoveredVehiclePopupProps) {
  if (
    vehicle === null ||
    vehicle.latitude === null ||
    vehicle.longitude === null
  ) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude!}
      longitude={vehicle.longitude!}
      anchor="bottom"
      offset={[0, -10] as [number, number]}
      className="hovered-vehicle-popup bold"
      closeButton={false}
    >
      <KmkId
        kmk_id={vehicle.full_kmk_id ?? "?????"}
        link={!!vehicle.full_kmk_id}
      />
    </Popup>
  );
}

interface ActiveVehicleSelectionProps {
  vehicle: Vehicle;
}

function ActiveVehicleSelection({ vehicle }: ActiveVehicleSelectionProps) {
  const geojson = useMemo(() => {
    return featureCollection(
      vehicle.latitude !== null && vehicle.longitude !== null
        ? [point([vehicle.longitude, vehicle.latitude], vehicle)]
        : []
    );
  }, [vehicle]);

  return (
    <Source id="active_vehicle_selection" type="geojson" data={geojson}>
      <Layer
        id="active_vehicle_selection_symbol"
        beforeId="vehicles"
        type="symbol"
        layout={{
          "icon-image": ["concat", ["get", "category"], "-selection"],
          "icon-size": vehicleIconSizeExpression,
          "icon-rotate": ["get", "bearing"],
          "icon-allow-overlap": true,
        }}
      />
    </Source>
  );
}

interface ActiveVehiclePopupProps {
  vehicle: Vehicle;
}

function ActiveVehiclePopup({ vehicle }: ActiveVehiclePopupProps) {
  if (vehicle.latitude === null || vehicle.longitude === null) {
    return null;
  }

  return (
    <Popup
      latitude={vehicle.latitude}
      longitude={vehicle.longitude}
      anchor="bottom"
      maxWidth="auto"
      offset={[0, -10] as [number, number]}
      className="clicked-vehicle-popup"
      closeButton={false}
      key={`${vehicle.key}_click`}
    >
      {vehicle.route_short_name !== null && (
        <>
          Linia:{" "}
          <strong>
            <Link
              to={getUrlForRoute(vehicle.route_short_name)}
              className="hidden-link"
            >
              {vehicle.route_short_name}
            </Link>
          </strong>
          <br />
        </>
      )}
      {vehicle.trip_headsign !== null && (
        <>
          Kierunek:{" "}
          <StopLink
            stopName={vehicle.trip_headsign}
            bold
            expandDepotName
            removeNz
          />
          <br />
        </>
      )}
      {vehicle.full_kmk_id !== null && (
        <>
          Numer taborowy:{" "}
          <strong>
            <KmkId kmk_id={vehicle.full_kmk_id} />
          </strong>
          <br />
        </>
      )}
      {vehicle.shift !== null && vehicle.shift !== vehicle.route_short_name && (
        <>
          Brygada:{" "}
          {vehicle.service_id !== null && vehicle.block_id !== null ? (
            <Link
              to={getUrlForBlock(
                vehicle.category,
                vehicle.service_id,
                vehicle.block_id
              )}
              className="bold hidden-link"
            >
              {vehicle.shift}
            </Link>
          ) : (
            <strong>{vehicle.shift}</strong>
          )}
          <br />
        </>
      )}
      <small>
        {vehicle.timestamp !== null && (
          <>
            <StatusIcon timestamp={vehicle.timestamp} />{" "}
            <Counter timestamp={vehicle.timestamp} /> |{" "}
          </>
        )}
        {getFullSourceName(vehicle.source)}
      </small>
    </Popup>
  );
}

interface ActiveVehiclePathPolylineProps {
  vehicle: Vehicle;
}

function ActiveVehiclePathPolyline({
  vehicle,
}: ActiveVehiclePathPolylineProps) {
  const { path, loading, error } = useVehiclePath(vehicle);

  const geojson = useMemo(() => {
    if (loading || error || path.length === 0) {
      return null;
    }
    return lineString(path.map((p) => [p.longitude, p.latitude]));
  }, [path, error, loading]);

  const color =
    (vehicle.timestamp ?? Infinity) < Date.now() / 1000 - 7 * 60 // 7 min
      ? "gray"
      : vehicle.category === "tram"
      ? "red"
      : vehicle.category === "bus"
      ? "blue"
      : vehicle.category === "mobilis"
      ? "navy"
      : "orange";

  return (
    <Source
      id="clicked-vehicle-path"
      type="geojson"
      data={geojson ?? emptyLine}
    >
      <Layer
        id="clicked_vehicle_path"
        beforeId="vehicles"
        type="line"
        layout={{
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-color": color,
          "line-width": 5,
          "line-opacity": 0.5,
        }}
        key={color} // force rerender to avoid color update delay
      />
    </Source>
  );
}

const ios = /iPhone|iPod|iPad/.test(navigator.platform);

export function Map() {
  const [source, setSource] = useLocalStorage<SourceType>(
    "map_source_v3",
    "gtfs"
  );

  const {
    loading,
    error,
    vehicles: allVehicles,
  } = useActiveVehicles(source, 10_000);

  const { hasFilter, shouldShowRoute } = useFilteredRouteShortNames();

  const filteredVehicles = useMemo(() => {
    let result = allVehicles.filter(
      (v) => v.latitude !== null && v.longitude !== null
    );
    if (hasFilter) {
      result = result.filter((v) => shouldShowRoute(v.route_short_name ?? ""));
    }
    return result;
  }, [hasFilter, shouldShowRoute, allVehicles]);

  const { stopGroups } = useStopGroups();

  const mapRef = useRef(null);

  const { selectionString } = useParams();

  const selection = useMemo(() => {
    if (selectionString === undefined) {
      return null;
    }
    if (isValidKmkId(selectionString)) {
      return { type: "vehicle", kmkId: selectionString };
    }
    if (isValidTtssVehicleId(selectionString)) {
      return { type: "vehicle_ttss", ttssVehicleId: selectionString };
    }
    const decodedStopName = decodeStopName(selectionString);
    if (stopGroups.some((sg) => sg.stop_name === decodedStopName)) {
      return { type: "stop", stopName: decodedStopName };
    }
    return null;
  }, [selectionString, stopGroups]);

  const navigate = useNavigate();

  const activeVehicle = useMemo(() => {
    switch (selection?.type) {
      case "vehicle":
        return (
          filteredVehicles.find(
            (v) =>
              v.kmk_id === selection.kmkId || v.full_kmk_id === selection.kmkId
          ) ?? null
        );
      case "vehicle_ttss":
        return (
          filteredVehicles.find(
            (v) => v.ttss_vehicle_id === selection.ttssVehicleId
          ) ?? null
        );
      default:
        return null;
    }
  }, [filteredVehicles, selection]);

  const activeStop = useMemo(() => {
    return selection?.type === "stop"
      ? stopGroups.find((sg) => sg.stop_name === selection.stopName) ?? null
      : null;
  }, [selection, stopGroups]);

  const [hoveredKmkId, setHoveredKmkId] = useState<string | null>(null);

  const hoveredVehicle = useMemo(() => {
    if (hoveredKmkId === null || hoveredKmkId === selection?.kmkId) {
      return null;
    }
    return filteredVehicles.find((v) => v.kmk_id === hoveredKmkId) ?? null;
  }, [filteredVehicles, hoveredKmkId, selection]);

  const [hoveredStopName, setHoveredStopName] = useState<string | null>(null);

  const [followActiveVehicle, setFollowActiveVehicle] = useState(false);

  const onMouseMove = useCallback((event: MapLayerMouseEvent) => {
    const maybeFeature = getNearestFeature(event.features, event.lngLat);
    if (maybeFeature?.properties.stop_lat) {
      // @ts-expect-error
      if (mapRef.current?.getZoom() < minZoomLevelForStopGroupLabels) {
        return;
      }
      setHoveredStopName(maybeFeature.properties.stop_name);
      setHoveredKmkId(null);
    } else if (maybeFeature?.properties.kmk_id) {
      setHoveredStopName(null);
      setHoveredKmkId(maybeFeature.properties.kmk_id);
    } else {
      setHoveredStopName(null);
      setHoveredKmkId(null);
    }
  }, []);

  const onMouseOut = useCallback(() => {
    setHoveredKmkId(null);
  }, []);

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const maybeFeature = getNearestFeature(event.features, event.lngLat);
      if (maybeFeature?.properties.stop_num) {
        navigate(
          getUrlForStopWithNumber(
            maybeFeature.properties.stop_name,
            maybeFeature.properties.stop_num
          )
        );
      } else if (maybeFeature?.properties.stop_lat) {
        // @ts-expect-error
        const currentZoom = mapRef.current?.getZoom();
        if (currentZoom < minZoomLevelForStopGroupLabels) {
          return;
        }
        if (
          selection?.type === "stop" &&
          selection.stopName === maybeFeature.properties.stop_name
        ) {
          navigate(getUrlForStop(maybeFeature.properties.stop_name));
        } else {
          navigate(getUrlForStopOnMap(maybeFeature.properties.stop_name), {
            replace: true,
          });
          // @ts-expect-error
          mapRef.current?.flyTo({
            center: [
              maybeFeature.properties.stop_lon,
              maybeFeature.properties.stop_lat,
            ],
            zoom: currentZoom < stopZoomLevel ? stopZoomLevel : undefined,
            duration: 350,
          });
        }
      } else if (
        source === "ttss" &&
        maybeFeature?.properties.ttss_vehicle_id
      ) {
        navigate(
          getUrlForVehicleOnMap(maybeFeature.properties.ttss_vehicle_id),
          { replace: true }
        );
        // @ts-expect-error
        mapRef.current?.flyTo({
          center: [
            maybeFeature.properties.longitude,
            maybeFeature.properties.latitude,
          ],
          duration: 350,
        });
      } else if (maybeFeature?.properties.kmk_id) {
        navigate(getUrlForVehicleOnMap(maybeFeature.properties.kmk_id), {
          replace: true,
        });
        // @ts-expect-error
        mapRef.current?.flyTo({
          center: [
            maybeFeature.properties.longitude,
            maybeFeature.properties.latitude,
          ],
          duration: 350,
        });
      } else {
        navigate(getUrlForMap(), { replace: true });
      }
    },
    [source, navigate, selection]
  );

  const onDragStart = useCallback(() => {
    setFollowActiveVehicle(false);
  }, []);

  useEffect(() => {
    if (selection?.type === "vehicle") {
      setFollowActiveVehicle(true);
    }
  }, [selection]);

  useEffect(() => {
    if (activeVehicle !== null && followActiveVehicle) {
      // @ts-expect-error
      mapRef.current?.flyTo({
        center: [activeVehicle.longitude, activeVehicle.latitude],
        duration: 350,
      });
    }
  }, [activeVehicle, followActiveVehicle]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageTitle title="Mapa" />
      <Navigation />
      {error ? (
        <div className="map-overlay">
          <div style={{ pointerEvents: "auto" }}>
            <Error error={error} />
          </div>
        </div>
      ) : loading ? (
        <div className="map-overlay">
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      <MapView
        initialViewState={{
          latitude: activeVehicle?.latitude ?? activeStop?.stop_lat ?? 50.06,
          longitude: activeVehicle?.longitude ?? activeStop?.stop_lon ?? 19.94,
          zoom: activeStop !== null ? stopZoomLevel : 13.1,
        }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        attributionControl={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
        onMouseMove={ios ? undefined : onMouseMove}
        onMouseOut={onMouseOut}
        onClick={onClick}
        onDragStart={onDragStart}
        cursor={hoveredKmkId || hoveredStopName ? "pointer" : "default"}
        interactiveLayerIds={[
          "vehicles",
          "stop_groups_circles",
          "stop_groups_labels",
          "stops_outlines",
          "stops_circles",
          "stops_letters",
          "stops_labels",
        ]}
        ref={mapRef}
      >
        <TicketZonesPolygons />
        <StopGroupsMarkers
          stopGroups={stopGroups}
          selectedStopName={selection?.stopName ?? null}
          hoveredStopName={hoveredStopName}
        />
        <StopsMarkers />
        <VehicleMarkers vehicles={filteredVehicles} />
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ReactFilterControl position="top-right" onClick={handleOpenModal} />
        <ScaleControl />
        <ReactSourceSelectControl
          position="bottom-left"
          value={source}
          onChange={setSource}
        />
        <AttributionControl compact={false} />
        <HoveredVehiclePopup vehicle={hoveredVehicle} />
        {activeVehicle && (
          <>
            <ActiveVehiclePopup vehicle={activeVehicle} />
            <ActiveVehicleSelection vehicle={activeVehicle} />
            <ActiveVehiclePathPolyline vehicle={activeVehicle} />
          </>
        )}
      </MapView>
      <RouteShortNameFilterModal show={showModal} onClose={handleCloseModal} />
    </div>
  );
}
